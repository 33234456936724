<template>
    <v-dialog v-model="dialogs.main.isOpened" max-width="500px">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="mx-2"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                fab
                small
            >
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                            dark
                        >
                            mdi-plus
                        </v-icon>
                    </template>
                    create new dashboard
                </v-tooltip>
            </v-btn>
        </template>
        <v-form v-model="isFormValid">
          <v-card>
              <v-card-title>
                  <span class="headline">new dashboard</span>
              </v-card-title>

              <v-card-text>
                  <v-container>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-text-field
                                v-model="dialogs.main.data.name"
                                label="name"
                                :rules="requiredRules"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-select
                            label="structure"
                            :items="structureListComputed"
                            v-model="dialogs.main.data.structureName"
                            item-text="name"
                            item-value="name"
                            @change="selectStructure"
                        />
                          <v-textarea
                            v-model="dialogs.main.data.structure"
                            label="structure"
                            :rules="jsonRules"
                            @input="changeToCustom"
                          />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12">
                          <v-text-field
                            v-model="dialogs.main.data.description"
                            label="description"
                          ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="6"
                      >
                        <v-switch
                          v-model="dialogs.main.data.isActive"
                          label="is active"
                          class="pa-3"
                        />
                        <v-switch
                            v-model="dialogs.main.data.isNativeDefault"
                            label="is native default"
                            class="pa-3"
                        />
                      </v-col>
                      <v-col
                          cols="6"
                      >
                        <v-switch
                          v-model="dialogs.main.data.isAirportDefault"
                          label="is airport default"
                          class="pa-3"
                        />
                        <v-switch
                          v-model="dialogs.main.data.isAirplaneDefault"
                          label="is airplane default"
                          class="pa-3"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
              </v-card-text>

              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeDialog('main')"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      color="blue darken-1"
                      text
                      :disabled="!isFormValid"
                      @click="save"
                  >
                      Save
                  </v-btn>
              </v-card-actions>
          </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import manageDialogsMixin from '../../mixins/manageDialogsMixin';

export default {
  name: 'DashboardFormDialog',
  mixins: [
    manageDialogsMixin,
  ],

  props: {
    structureList: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    isFormValid: false,

    dialogs: {
      main: {
        isOpened: false,
        data: {
          id: null,
          name: '',
          structureName: '<custom>',
          structure: JSON.stringify({}),
          description: '',
          isActive: false,
          isAirportDefault: false,
          isAirplaneDefault: false,
          isNativeDefault: false,
        },
        defaults: {
          id: null,
          name: '',
          structureName: '<custom>',
          structure: JSON.stringify({}),
          description: '',
          isActive: false,
          isAirportDefault: false,
          isAirplaneDefault: false,
          isNativeDefault: false,
        },
      },
    },

    requiredRules: [
      (v) => !!v || 'Field is required',
    ],

    jsonRules: [
      (v) => {
        let isValid = true;
        try {
          JSON.parse(v);
        } catch (e) {
          isValid = false;
        }

        return isValid || 'Structure is not a valid JSON';
      },
    ],
  }),

  methods: {
    ...mapActions({
      setSnack: 'snackbar/setState',
    }),

    save() {
      this.$requestHelpers
        .post('api/admin/dashboard',
          {
            name: this.dialogs.main.data.name,
            structure: this.dialogs.main.data.structure,
            description: this.dialogs.main.data.description,
            is_active: this.dialogs.main.data.isActive,
            default_airport: this.dialogs.main.data.isAirportDefault,
            default_airplane: this.dialogs.main.data.isAirplaneDefault,
            default_native: this.dialogs.main.data.isNativeDefault,
          }).then((response) => {
          this.$emit('create', response.data.result);

          this.closeDialog('main');
        });
    },

    selectStructure() {
      this.dialogs.main.data.structure = JSON.stringify(this.structureList.find(
        (el) => el.name === this.dialogs.main.data.structureName,
      ).structure);
    },

    changeToCustom() {
      if (this.dialogs.main.data.structureName !== '<custom>') {
        this.dialogs.main.data.structureName = '<custom>';
      }
    },
  },

  computed: {
    structureListComputed() {
      const structureList = [...this.structureList];

      structureList.unshift({
        name: '<custom>',
        structure: {},
      });

      return structureList;
    },
  },
};
</script>
