<template>
  <v-container v-if="isLoadedStructure">
    <v-row>
      <v-col cols="9">View</v-col>
      <v-col cols="3">Options</v-col>
    </v-row>
    <v-row>
      <v-col
        cols="9"
      >
        <v-tabs
            v-model="currentAnalyticsTab"
        >
          <v-tab
              v-for="(tab, key) in structureComputed.tabs"
              :key="key"
          >
            {{ tab.name ? tab.name : 'empty name' }}
            <v-edit-dialog>
              <v-icon
                  color="orange"
              >
                mdi-pen
              </v-icon>
              <template v-slot:input>
                <v-text-field
                    v-model="tab.name"
                    label="Edit"
                    single-line
                    counter
                />
              </template>
            </v-edit-dialog>

            <v-btn icon @click="removeTab(key)">
              <v-icon
                  color="red"
              >
                mdi-delete
              </v-icon>
            </v-btn>
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col
        :cols="3"
      >
        Tabs
        <v-btn icon @click="addTab()">
          <v-icon
              color="primal"
          >
            mdi-plus
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        :cols="9"
      >
        <strong>Static layout</strong>
        <LayoutBuilder
            v-if="structureComputed.static.is_shown"
            :layout-item.sync="structureComputed.static"
            :is-edit-mode="true"
        />
      </v-col>
      <v-col
        :cols="3"
      >
        <strong>Static element</strong>
        <v-checkbox
            v-model="structureComputed.static.is_shown"
            label="show  static element"
        />

        <div v-if="structureComputed.static.layout">
          <div
              v-for="(position, key) in structureComputed.static.layout.positions"
              :key="key"
          >
            Position: {{ key }}
            <v-text-field
                v-model.number="position.options.max_capacity"
                label="max capacity"
                type="number"
                :rules="[(v) => (v >= 1 && v <= 3) || '[1,3]']"
                single-line
                @input="changeCapacity(position)"
            />
            <v-select
                label="class"
                v-model="position.options.class"
                :items="dashboardConstruction.classes.list"
            />
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
          :cols="9"
      >
        <v-tabs-items
            v-model="currentAnalyticsTab"
        >
          <v-tab-item
              v-for="(tab, key) in structureComputed.tabs"
              :key="key"
          >
            <strong>Current tab layout</strong>
            <LayoutBuilder
                :layout-item.sync="structureComputed.tabs[key]"
                :is-edit-mode="true"
            />
          </v-tab-item>
        </v-tabs-items>

      </v-col>
      <v-col
          :cols="3"
      >
        <div
            v-if="structureComputed.tabs[currentAnalyticsTab]
            && structureComputed.tabs[currentAnalyticsTab].layout"
        >
          <strong>Tab elements</strong>
          <div
            v-for="(position, key) in structureComputed.tabs[currentAnalyticsTab].layout.positions"
            :key="key"
          >
            Position: {{ key }}
            <v-text-field
                v-model.number="position.options.max_capacity"
                label="max capacity"
                type="number"
                :rules="[(v) => (v >= 1 && v <= 3) || '[1,3]']"
                single-line
                @input="changeCapacity(position)"
            />
            <v-select
                label="class"
                v-model="position.options.class"
                :items="dashboardConstruction.classes.list"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import LayoutBuilder from './layouts/LayoutBuilder.vue';

export default {
  name: 'DashboardEditor',

  components: { LayoutBuilder },

  props: {
    dashboardId: {
      type: Number,
    },

    dashboardStructure: {
      type: Object,
    },

    metricsData: {
      type: Object,
    },
  },

  data() {
    return {
      menu: [],

      isLoadedStructure: false,

      dashboardConstruction: {
        classes: [],
      },

      currentAnalyticsTab: 0,
    };
  },

  mounted() {
    // eslint-disable-next-line max-len
    this.dashboardConstruction.classes = this.listItem(this.$constants.DATA_LISTS.DASHBOARD_ELEMENTS_CLASSES.type);
    this.updateList(this.$constants.DATA_LISTS.DASHBOARD_ELEMENTS_CLASSES);

    this.setMetricsData(this.metricsData);

    this.structureComputed = JSON.parse(JSON.stringify(this.dashboardStructure));

    this.isLoadedStructure = true;
  },

  methods: {
    ...mapActions({
      setMetricsData: 'metrics/setMetricsData',
      updateList: 'datalists/updateList',
    }),

    changeCapacity(position) {
      const maxCapacity = position.options.max_capacity;
      const itemsNumber = position.metric_items.length;

      if (maxCapacity === itemsNumber) {
        return;
      }

      if (itemsNumber > maxCapacity) {
        position.metric_items.splice(maxCapacity);
      } else {
        // eslint-disable-next-line no-param-reassign
        position.metric_items.length = maxCapacity;
        position.metric_items.fill({
          slug: 'empty',
          metrics: [],
        }, itemsNumber, maxCapacity);
      }
    },

    addTab() {
      this.structureComputed.tabs.push({ name: 'new tab' });
    },

    removeTab(num) {
      this.structureComputed.tabs.splice(num, 1);
    },
  },

  computed: {
    ...mapGetters({
      listItem: 'datalists/listItem',
    }),

    structureComputed: {
      get() {
        return this.dashboardStructure;
      },
      set(val) {
        this.$emit('update:dashboardStructure', val);
      },
    },
  },

  deactivated() {
    this.$destroy();
  },
};
</script>

<style scoped>

</style>
