<template>
  <v-container>
    <v-row>
      <v-col>
        <v-tabs
            v-model="currentAnalyticsTab"
        >
          <v-tab
              v-for="(tab, key) in dashboardStructure.tabs"
              :key="key"
          >
            {{ tab.name }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <div class="main-body">
      <LayoutBuilder
          v-if="dashboardStructure.static.layout.length && dashboardStructure.static.is_shown"
          :layout-item="dashboardStructure.static"
      />

      <v-tabs-items
          v-model="currentAnalyticsTab"
      >
        <v-tab-item
            v-for="(tab, key) in dashboardStructure.tabs"
            :key="key"
        >
          <LayoutBuilder
              :layout-item="tab"
          />
        </v-tab-item>
      </v-tabs-items>

    </div>

  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import LayoutBuilder from './layouts/LayoutBuilder.vue';

export default {
  name: 'DashboardPreview',

  components: { LayoutBuilder },

  props: {
    dashboardStructure: {
      type: Object,
      default: () => ({}),
    },

    metricsData: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return ({
      currentAnalyticsTab: 0,
    });
  },

  mounted() {
    this.setMetricsData(this.metricsData);
  },

  methods: {
    ...mapActions({
      setMetricsData: 'metrics/setMetricsData',
    }),
  },
};

</script>
<style scoped>

</style>
