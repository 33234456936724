<template>
  <v-container>
    <ConfirmDialog
        :is-open.sync="dialogs.showPreview.isOpened"
        dialogMaxWidth="1200px"
        :show-confirm-button="false"
    >
      <template v-slot:title>
        Preview for {{ getDialogData('showPreview', 'dashboardName') }}
      </template>
      <template v-slot:body>
        <DashboardPreview
            :dashboard-structure="getDialogData('showPreview', 'dashboardStructure')"
            :metrics-data="metricsData"
        />
      </template>
    </ConfirmDialog>

    <ConfirmDialog
        v-if="dialogs.editDashboardStructure.isOpened"
        :is-open.sync="dialogs.editDashboardStructure.isOpened"
        dialogMaxWidth="1500px"
        confirm-button-text="Save"
        @confirm="saveStructure"
        persistent
    >
      <template v-slot:title>
        Edit {{ getDialogData('editDashboardStructure', 'dashboardName') }}
      </template>
      <template v-slot:body>
        <DashboardEditor
            :dashboard-id="getDialogData('editDashboardStructure', 'dashboardId')"
            :dashboard-structure.sync="dialogs.editDashboardStructure.data.dashboardStructure"
            :metrics-data="metricsData"
        />
      </template>
    </ConfirmDialog>

    <v-data-table
      :options.sync="options"
      :footer-props="{ itemsPerPageOptions: [10, 25, 50, -1] }"
      :headers="headers"
      :items="dashboards"
      :single-expand="true"
    >
      <template v-slot:top>
        <v-toolbar
          class="mt-4"
          flat
        >
          <DashboardFormDialog
              v-on:create="addDashboard"
              :structure-list="structureList"
          />

        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
            icon
            @click="remove(item.id)"
            :disabled="isDefault(item)"
        >
          <v-icon
              color="red"
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>

      <template v-slot:item.name="{ item }">
        <v-row>
        <v-edit-dialog
          @save="save(itemClone, item)"
          @open="itemClone = {...item}"
        >
          {{ item.name }}
          <template v-slot:input>
            <v-text-field
              v-model="itemClone.name"
              label="Edit"
              single-line
              counter
            />
          </template>
        </v-edit-dialog>
        </v-row>
      </template>

      <template v-slot:item.preview="{ item }">
        <v-btn
            icon
            @click="openDialog(
              'showPreview',
              {
                dashboardName: item.name,
                dashboardStructure: item.structure
              }
            )"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                mdi-eye
              </v-icon>
            </template>
            show preview
          </v-tooltip>
        </v-btn>
        <v-btn
            icon
            :disabled="isDefault(item)"
            @click="openDialog(
              'editDashboardStructure',
              {
                dashboardId: item.id,
                dashboardName: item.name,
                dashboardStructure: item.structure
              }
            )"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="orange"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pen
              </v-icon>
            </template>
            edit
          </v-tooltip>
        </v-btn>
      </template>

      <template v-slot:item.description="{ item }">
        <v-edit-dialog
          @save="save(itemClone, item)"
          @open="itemClone = {...item}"
        >
          <div v-if="item.description" class="font-weight-black">{{ item.description }}</div>
          <div v-else class="font-italic font-weight-thin red--text" v-text="'empty'"/>
          <template v-slot:input>
            <v-text-field
              v-model="itemClone.description"
              label="Edit"
              single-line
              counter
            />
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:item.default_airport="{ item }">
        <v-switch
            v-model="item.default_airport"
            @change="save(item, item)"
        />
      </template>

      <template v-slot:item.default_airplane="{ item }">
        <v-switch
            v-model="item.default_airplane"
            @change="save(item, item)"
        />
      </template>

      <template v-slot:item.default_native="{ item }">
        <v-switch
            v-model="item.default_native"
            @change="save(item, item)"
        />
      </template>

      <template v-slot:item.is_active="{ item }">
        <v-switch
            v-model="item.is_active"
            @change="save(item, item)"
        />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DashboardFormDialog from '../components/dasboard/DashboardFormDialog.vue';
import ConfirmDialog from '../components/dialogs/ConfirmDialog.vue';
import manageDialogsMixin from '../mixins/manageDialogsMixin';
import DashboardPreview from '../components/dasboard/DashboardPreview.vue';
import DashboardEditor from '../components/dasboard/DashboardEditor.vue';

export default {
  name: 'Dashboards',
  components: {
    DashboardEditor,
    DashboardPreview,
    ConfirmDialog,
    DashboardFormDialog,
  },

  mixins: [
    manageDialogsMixin,
  ],

  data: () => ({
    itemClone: {},
    dashboards: [],
    dashboardConstructionElements: {
      structure: {},
      metrics: {},
      layouts: {},
      classes: {},
    },
    metricsData: {},

    headers: [
      { text: 'id', value: 'id' },
      { text: 'name', value: 'name' },
      { text: 'preview', value: 'preview' },
      { text: 'description', value: 'description' },
      { text: 'is active', value: 'is_active' },
      { text: 'default for airport', value: 'default_airport' },
      { text: 'default for airplane', value: 'default_airplane' },
      { text: 'default for native', value: 'default_native' },
      { text: '', value: 'actions', sortable: false },
    ],
    options: {
      itemsPerPage: 10,
      sortBy: ['id'],
      sortDesc: [false],
      mustSort: true,
      multiSort: false,
    },

    dialogs: {
      editDashboardStructure: {
        isOpened: false,
        data: {
          dashboardId: null,
          dashboardName: '',
          dashboardStructure: {},
        },
        defaults: {
          dashboardId: null,
          dashboardName: '',
          dashboardStructure: {},
        },
      },

      showPreview: {
        isOpened: false,
        data: {
          dashboardName: '',
          dashboardStructure: {},
        },
        defaults: {
          dashboardName: '',
          dashboardStructure: {},
        },
      },
    },
  }),

  async created() {
    this.setIsUseStaticMetricsData(true);

    this.$requestHelpers
      .get('api/admin/dashboard')
      .then((response) => {
        this.dashboards = response.data;
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            this.$router.push({ name: 'root' });
          }
        }
      });

    // eslint-disable-next-line max-len
    this.dashboardConstructionElements.structure = this.listItem(this.$constants.DATA_LISTS.DASHBOARD_ELEMENTS_STRUCTURE.type);
    // eslint-disable-next-line max-len
    this.dashboardConstructionElements.metrics = this.listItem(this.$constants.DATA_LISTS.DASHBOARD_ELEMENTS_METRICS.type);
    // eslint-disable-next-line max-len
    this.dashboardConstructionElements.layouts = this.listItem(this.$constants.DATA_LISTS.DASHBOARD_ELEMENTS_LAYOUTS.type);
    // eslint-disable-next-line max-len
    this.dashboardConstructionElements.classes = this.listItem(this.$constants.DATA_LISTS.DASHBOARD_ELEMENTS_CLASSES.type);
    await this.updateList(this.$constants.DATA_LISTS.DASHBOARD_ELEMENTS_STRUCTURE);
    await this.updateList(this.$constants.DATA_LISTS.DASHBOARD_ELEMENTS_METRICS);
    await this.updateList(this.$constants.DATA_LISTS.DASHBOARD_ELEMENTS_LAYOUTS);
    await this.updateList(this.$constants.DATA_LISTS.DASHBOARD_ELEMENTS_CLASSES);

    const metricsData = {};

    this.dashboardConstructionElements.metrics.list.forEach((metric) => {
      metricsData[metric.slug] = metric.data;
    });

    this.setStaticMetricsData(metricsData);
  },

  methods: {
    ...mapActions({
      setSnack: 'snackbar/setState',
      setIsUseStaticMetricsData: 'metrics/setIsUseStaticMetricsData',
      setStaticMetricsData: 'metrics/setStaticMetricsData',
      updateList: 'datalists/updateList',
    }),

    addDashboard(dashboard) {
      this.dashboards.push({ ...dashboard, structure: JSON.parse(dashboard.structure) });
    },

    save(itemClone, item) {
      return this.$requestHelpers
        .patch(`api/admin/dashboard/${item.id}`, itemClone)
        .then((response) => {
          Object.assign(item, response.data.result);
        });
    },

    saveStructure() {
      const id = this.getDialogData('editDashboardStructure', 'dashboardId');
      const structure = this.getDialogData('editDashboardStructure', 'dashboardStructure');

      const item = this.dashboards.find(
        (el) => el.id === id,
      );

      const itemClone = { ...item, structure: JSON.stringify(structure) };
      this.save(itemClone, item).finally(() => {
        this.closeDialog('editDashboardStructure');
      });
    },

    remove(id) {
      const dashboard = this.dashboards.find((el) => el.id === id);
      this.$confirm(`Are you sure you want to delete dashboard ${dashboard.name}?`).then((res) => {
        if (!res) return;

        this.$requestHelpers
          .delete(`api/admin/dashboard/${id}`)
          .then(() => {
            this.dashboards = this.dashboards.filter((e) => e.id !== id);
          });
      });
    },

  },

  computed: {
    ...mapGetters({
      listItem: 'datalists/listItem',
    }),

    structureList() {
      const structureList = this.dashboards.map((el) => ({
        name: el.name,
        structure: el.structure,
      }));

      structureList.unshift({
        name: '<empty>',
        // eslint-disable-next-line max-len
        structure: this.dashboardConstructionElements && this.dashboardConstructionElements.structure
          ? this.dashboardConstructionElements.structure.list
          : {},
      });

      return structureList;
    },

    // eslint-disable-next-line max-len
    isDefault: () => (item) => !!(item.default_airport || item.default_airplane || item.default_native),
  },

  deactivated() {
    this.$destroy();
  },
};
</script>
